import { BitfStrapiModel } from '@common/libs/bitforce/core/models';

export class Revenue extends BitfStrapiModel {
  type: string;
  value: number;

  constructor(data: Partial<Revenue> = {}) {
    super({ data });
  }

  get serialised() {
    return {};
  }
}
