import {
  EApiCallStateNames,
  EApiRequestPartKeys,
  EBitfApiRequestPartsNames,
  EBitfApiSortDirection,
} from '@enums';
import { IBitfApiCallState } from '@interfaces';
import { DefaultApiCallStateMapper } from './default-api-call-state.mapper';
import {
  BitfStrapiFormItemsConfigApiRequestPart,
  BitfStrapiPaginationApiRequestPart,
  BitfStrapiSearchApiRequestPart,
  BitfStrapiSortApiRequestPart,
} from '@common/libs/bitforce/core/api-call-state/bitf-strapi-api-call-state';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.CONTRACTS,
    requestParts: [
      new BitfStrapiPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 30 },
        options: { pageIndexOffset: 1 },
      }),

      new BitfStrapiSortApiRequestPart({
        key: EApiRequestPartKeys.AQ_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: { data: { property: 'aq', direction: EBitfApiSortDirection.UNDEFINED } },
      }),
      new BitfStrapiSortApiRequestPart({
        key: EApiRequestPartKeys.SELLER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: { data: { property: 'seller', direction: EBitfApiSortDirection.UNDEFINED } },
      }),
      new BitfStrapiSortApiRequestPart({
        key: EApiRequestPartKeys.CONVENTION_ACTIVATION_DATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'conventionActivationDate', direction: EBitfApiSortDirection.ASC },
        },
      }),
      new BitfStrapiSortApiRequestPart({
        key: EApiRequestPartKeys.CONVENTION_DURATION_IN_MONTHS_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'conventionDurationInMonths', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfStrapiSortApiRequestPart({
        key: EApiRequestPartKeys.RENEWAL_DURATION_IN_MOTHS_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'renewalDurationInMonths', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfStrapiSearchApiRequestPart(),
      new BitfStrapiFormItemsConfigApiRequestPart(),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.OPPORTUNITIES,
    requestParts: [
      new BitfStrapiPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 30 },
        options: { pageIndexOffset: 1 },
      }),

      new BitfStrapiSortApiRequestPart({
        key: EApiRequestPartKeys.OPPORTUNITY_ID_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: { data: { property: 'opportunityId', direction: EBitfApiSortDirection.UNDEFINED } },
      }),
      new BitfStrapiSortApiRequestPart({
        key: EApiRequestPartKeys.OPPORTUNITY_NAME_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'opportunityName', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfStrapiSortApiRequestPart({
        key: EApiRequestPartKeys.BUSINESS_NAME_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: { data: { property: 'businessName', direction: EBitfApiSortDirection.UNDEFINED } },
      }),
      new BitfStrapiSortApiRequestPart({
        key: EApiRequestPartKeys.SELLER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: { data: { property: 'seller', direction: EBitfApiSortDirection.UNDEFINED } },
      }),
      new BitfStrapiSortApiRequestPart({
        key: EApiRequestPartKeys.OPPORTUNITY_UPDATED_AT_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'opportunityUpdatedAt', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfStrapiSortApiRequestPart({
        key: EApiRequestPartKeys.STATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: { data: { property: 'state', direction: EBitfApiSortDirection.UNDEFINED } },
      }),
      new BitfStrapiSearchApiRequestPart(),
      new BitfStrapiFormItemsConfigApiRequestPart(),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
