import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { PurchaseRequest } from './purchase-request.model';

export class WorkOrder extends BitfStrapiModel {
  idSiebel: number;
  mosCrDm: number;
  totalBo: number;
  totalEm: number;
  entryGoodsStatus: string;
  purchaseRequests: PurchaseRequest[];

  constructor(data: Partial<WorkOrder> = {}) {
    super({ data });

    if (data.purchaseRequests) {
      this.purchaseRequests = data.purchaseRequests.map(
        purchaseRequest => new PurchaseRequest(purchaseRequest)
      );
    }
  }

  get serialised() {
    return {
      idSiebel: this.idSiebel,
      mosCrDm: this.mosCrDm,
      totalBo: this.totalBo,
      totalEm: this.totalEm,
      entryGoodsStatus: this.entryGoodsStatus,
      purchaseRequests: this.purchaseRequests?.map(purchaseRequest => purchaseRequest.serialised),
    };
  }
}
