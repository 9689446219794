import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EWorkOrderStatus } from '@web/enums/work-order-status.enum';

import { TranslateService } from '@ngx-translate/core';
import { EOpportunityStatus } from '@web/enums/opportunity-status.enum';
import { EStatusType } from '@web/enums/status-type.enum';
import { eEntitiesStatus } from '@web/enums/app.enum';

@Component({
    selector: 'tim-status-chip',
    templateUrl: './status-chip.component.html',
    styleUrl: './status-chip.component.scss',
    standalone: false
})
export class StatusChipComponent implements OnChanges {
  @Input() status: EWorkOrderStatus | EOpportunityStatus;
  @Input() type: EStatusType;
  @Input() isEditable: boolean = false;
  @Output() statusChange: EventEmitter<EWorkOrderStatus> = new EventEmitter<EWorkOrderStatus>();

  public eStatus = eEntitiesStatus;
  public statusClass = '';
  public statusOptions = [];

  constructor(public translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.status) {
      this.loadStatusChip();
      switch (this.type) {
        case 'OPPORTUNITY':
          this.statusOptions = Object.values(EOpportunityStatus);
          break;

        case 'WORK_ORDER':
          this.statusOptions = Object.values(EWorkOrderStatus);
          break;

        default:
          break;
      }
    }
  }

  onStatusChange(newStatus: EWorkOrderStatus) {
    this.statusChange.emit(newStatus);
  }

  loadStatusChip() {
    switch (this.status) {
      case eEntitiesStatus.COMPLETED:
      case eEntitiesStatus.INVOICED:
        this.statusClass = 'completed-chip';
        break;

      case eEntitiesStatus.PARTIAL:
      case eEntitiesStatus.OPEN:
      case eEntitiesStatus.CONTRACTUALIZED:
      case eEntitiesStatus.PRODUCTION_AND_SIGNATURE_OF_ATTACHMENTS:
      case eEntitiesStatus.TAKEN_OVER_CO:
      case eEntitiesStatus.TAKEN_OVER_PS:
      case eEntitiesStatus.TAKEN_OVER_DELIVERY_ON_SITE:
      case eEntitiesStatus.TAKEN_OVER_PURCHASING:
      case eEntitiesStatus.SUPPLY_TESTING_EM_PARTIAL_OR_TOTAL:
        this.statusClass = 'partial-chip';
        break;

      default:
        this.statusClass = '';
        break;
    }
  }
}
