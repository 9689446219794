import { Component, inject, OnInit } from '@angular/core';
import { EBitfCloseEventStatus, EBitfSidenavMode, EBitfSidenavPosition } from '@common/enums';
import { AuthService, StoreService } from '@web/core/services';
import { MenuComponent } from '../menu/menu.component';
import { BitfMatMultipleSidenavGlobalService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-multiple-sidenav-global.service';
import { FormControl, Validators } from '@angular/forms';
import { eStoreActions } from '@web/enums';
import { Router } from '@angular/router';

@Component({
    selector: 'tim-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit {
  /**
   * Imports
   */
  router = inject(Router);

  /**
   * Public
   */
  isSidenavOpen = false;
  groupFormControl = new FormControl(null, [Validators.required]);
  groupCompare = (i1, i2) => {
    return i1.documentId === i2.documentId;
  };

  constructor(
    public storeService: StoreService,
    private bitfMatMultipleSidenavGlobalService: BitfMatMultipleSidenavGlobalService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.onOpenSidenav();
    this.isSidenavOpen = true;

    this.groupFormControl.setValue(this.storeService.store.group);
    this.groupFormControl.valueChanges.subscribe(group => {
      this.storeService.setStore(store => {
        store.group = group;
      }, eStoreActions.SET_GROUP);

      this.router.navigate(['/', this.router.routerState.snapshot.url?.split('/')[1]], {
        onSameUrlNavigation: 'reload',
      });
    });
  }

  onOpenSidenav() {
    this.isSidenavOpen = true;
    this.bitfMatMultipleSidenavGlobalService
      .open({
        component: MenuComponent,
        sidenavOptions: {
          mode: EBitfSidenavMode.SIDE,
          position: EBitfSidenavPosition.START,
          disableClose: true,
        },
      })
      .subscribe();
  }

  onSignOut() {
    this.authService.signOut();
  }

  closeSidenav() {
    this.isSidenavOpen = false;
    this.bitfMatMultipleSidenavGlobalService.close(
      { status: EBitfCloseEventStatus.CLOSE },
      EBitfSidenavPosition.START
    );
  }
}
