import { inject, Injectable, Injector } from '@angular/core';
import { Contract } from '@common/core/models';
import { IBitfApiRequest } from '@common/interfaces';
import { BitfApiService } from '@common/libs/bitforce/core/services/api/bitf-api.service';
import { StoreService } from '../store.service';

@Injectable({
  providedIn: 'root',
})
export class ContractsService extends BitfApiService {
  /**
   * Imports
   */
  private storeService = inject(StoreService);

  constructor(public injector: Injector) {
    super('contracts', injector);
  }

  getContracts(apiRequest: IBitfApiRequest) {
    return this.get<Contract>({
      populate: '*',
      filter: {
        company: {
          documentId: { $in: this.storeService.store?.group?.companies?.map(c => c.documentId) },
        },
        ...apiRequest.filter,
      },
      ...apiRequest,
    });
  }

  getContractDetails(id: string) {
    return this.getById<Contract>({ id: id });
  }
}
