import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { EntryGoods } from './entry-goods.model';

export class PurchaseRequest extends BitfStrapiModel {
  idSiebel: number;
  wplrda: number;
  wplrdaDate: Date;
  bobcbe: number;
  bobcbeDate: Date;
  supplier: string;
  ingaggioPS: number;
  omsCode: number;
  offerAmount: number;
  entryGoods: EntryGoods[];

  constructor(data: Partial<PurchaseRequest> = {}) {
    super({ data });

    if (data.entryGoods) {
      this.entryGoods = data.entryGoods?.map(entryGoods => new EntryGoods(entryGoods));
    }
  }

  get serialised() {
    return {
      idSiebel: this.idSiebel,
      wplrda: this.wplrda,
      wplrdaDate: this.wplrdaDate,
      bobcbe: this.bobcbe,
      bobcbeDate: this.bobcbeDate,
      supplier: this.supplier,
      ingaggioPS: this.ingaggioPS,
      omsCode: this.omsCode,
      offerAmount: this.offerAmount,
      entryGoods: this.entryGoods?.map(entryGood => entryGood.documentId),
    };
  }
}
