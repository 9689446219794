<div class="sidenav-sm h-100 justify-content-between d-flex flex-column">
  <div class="flex-grow-1">
    <mat-nav-list class="p-0">
      <a mat-list-item
        #linkActive="routerLinkActive"
        routerLinkActive
        [activated]="linkActive.isActive"
        routerLink="/contracts"
        [ngClass]="linkActive.isActive && 'selected'">
        <mat-icon matListItemIcon>description</mat-icon>
        <div matListItemTitle>{{'COMMON.LABEL.CONTRACTS' | translate}}</div>
      </a>
      <a mat-list-item
        #LinkActiveOpportunity="routerLinkActive"
        routerLinkActive
        [activated]="LinkActiveOpportunity.isActive"
        routerLink="/opportunities"
        [ngClass]="LinkActiveOpportunity.isActive && 'selected'">
        <mat-icon matListItemIcon>description</mat-icon>
        <div matListItemTitle>{{'COMMON.LABEL.OPPORTUNITIES' | translate}}</div>
      </a>
    </mat-nav-list>
  </div>
  <div class="p-4">
    <span class="d-flex mat-body-small align-items-center">
      v{{appVersion}}
    </span>
  </div>
</div>