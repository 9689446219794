import { Component, Input } from '@angular/core';
import { BitfSearchComponent } from '../bitf-search.component';

@Component({
  selector: 'bitf-mat-search',
  templateUrl: './bitf-mat-search.component.html',
  styleUrls: ['./bitf-mat-search.component.scss'],
  standalone: false,
})
export class BitfMatSearchComponent extends BitfSearchComponent {
  @Input() fontSize?: string;
}
