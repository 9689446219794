import { inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { IBitfApiResponse } from '@interfaces';
import { Store, User } from '@models';
import { tap } from 'rxjs/operators';
import { StoreService } from '../store.service';
import { eStoreActions } from '@web/enums';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BitfApiService {
  private storeService = inject(StoreService);

  constructor(public injector: Injector) {
    super('users', injector);
  }

  getMe(): Observable<IBitfApiResponse<User>> {
    return super
      .request<User>({
        path: '/me',
        populate: {
          groups: {
            populate: { companies: true },
          },
          role: true,
        },
        method: 'GET',
        disableHideLoader: true,
      })
      .pipe(
        tap(response => {
          this.storeService.setStore((store: Store) => {
            store.group = response.content.groups.length > 0 ? response.content.groups[0] : null;
          }, eStoreActions.SET_GROUP);
        })
      );
  }
}
