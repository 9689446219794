import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { Opportunity } from './opportunity.model';
import { Contract } from './contract.model';

export class Company extends BitfStrapiModel {
  name: string;
  opportunities: Opportunity[];
  contracts: Contract[];

  constructor(data: Partial<Company> = {}) {
    super({ data });

    if (data.contracts) {
      this.contracts = data.contracts.map(contract => new Contract(contract));
    }

    if (data.opportunities) {
      this.opportunities = data.opportunities.map(opportunity => new Opportunity(opportunity));
    }
  }

  get serialised() {
    return {};
  }
}
