import { EBitfStoreActions } from '@common/enums';
import { EOpportunityStatus } from '@web/enums/opportunity-status.enum';
import { EWorkOrderStatus } from './work-order-status.enum';

export enum EWebStoreActions {
  NEW_WEB_ACTION = 'NEW_WEB_ACTION',
  SET_GROUP = 'SET_GROUP',
}

export const eStoreActions = { ...EBitfStoreActions, ...EWebStoreActions };

export const eEntitiesStatus = { ...EWorkOrderStatus, ...EOpportunityStatus };
