import * as Models from '@models';

export const modelsMap = new Map<string, any>([
  ['users', Models.User],
  ['contracts', Models.Contract],
  ['opportunities', Models.Opportunity],
  ['purchase-requests', Models.PurchaseRequest],
  ['invoices', Models.Invoice],
  ['work-orders', Models.WorkOrder],
  ['revenues', Models.Revenue],
  ['entry-goods', Models.EntryGoods],
]);
