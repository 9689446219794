import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
export enum EWorkOrderStatus {
  COMPLETED = 'COMPLETED',
  PARTIAL = 'PARTIAL',
}

const toTranslate = [
  bitfToTranslate('OPPORTUNITIES.STATUS.COMPLETED'),
  bitfToTranslate('OPPORTUNITIES.STATUS.PARTIAL'),
];
