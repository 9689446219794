import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

import { BitfFormItemComponent } from '../../bitf-form-item.component';
import { BitfOption } from '@bitf/core/models/bitf-option.model';
import { compareWith } from '@bitf/utils/bitf-material.utils';

@Component({
  selector: 'bitf-mat-form-option',
  templateUrl: './bitf-mat-form-option.component.html',
  styleUrls: ['./bitf-mat-form-option.component.scss'],
  standalone: false,
})
export class BitfMatFormOptionComponent extends BitfFormItemComponent implements OnInit {
  @Input() matFormFieldClass;

  @Input() appearance: MatFormFieldAppearance;

  @Input() multiple;

  @Input() addEmptyValue = false;

  @Input() useDefaultOption = false;

  @Input() setValueTo: string;

  @Input() label = '';

  @Input() selectUnselectAllFields = false;

  @Input() useObject = false;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('matSelectComponent', { static: true }) matSelectComponent: MatSelect;

  compareWith = compareWith;

  constructor(public injector: Injector, private translate: TranslateService) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.formItem.type === 'multipleSelect') {
      this.multiple = true;
    }

    if (this.addEmptyValue) {
      if (!this.formItem.options.find(option => option.id === null)) {
        const emptyOption = new BitfOption({ id: null, label: '' });
        this.formItem.options.unshift(emptyOption);
      }
    }

    setTimeout(() => {
      if (this.hasDefaultValue() && !this.hasValue() && this.useDefaultOption) {
        if (this.multiple) {
          const compareDefaultValues = this.formItem.default as Array<any>;
          let defaultOptions: Array<any>;

          if (this.useObject) {
            defaultOptions = this.formItem.options.filter(item =>
              compareDefaultValues.find(compareDefaultValue => compareDefaultValue.id === item.id)
            );
            this.formControl.setValue(defaultOptions);
          } else {
            defaultOptions = this.formItem.options.filter(item => compareDefaultValues.includes(item.id));
            this.formControl.setValue(defaultOptions.map(defaultValue => defaultValue.id));
          }
        } else {
          let compareDefaultValue = this.formItem.default;
          if (this.useObject) {
            compareDefaultValue = (this.formItem.default as BitfOption).id;
          }
          const defaultOptionValue = this.formItem.options.find(item => item.id === compareDefaultValue);
          if (defaultOptionValue && defaultOptionValue.id !== undefined) {
            if (this.useObject) {
              this.formControl.setValue(new BitfOption(defaultOptionValue));
            } else {
              this.formControl.setValue(defaultOptionValue.id);
            }
          }
        }
      }

      if (this.formItem.options.length === 1 && this.formItem.isRequired && !this.multiple) {
        if (this.useObject) {
          this.formControl.setValue(new BitfOption(this.formItem.options[0]));
        } else {
          this.formControl.setValue(this.formItem.options[0].id);
        }
      }
    });
  }

  hasDefaultValue(): boolean {
    return this.formItem.default !== null && this.formItem.default !== undefined;
  }

  hasValue(): boolean {
    return this.formControl.value !== undefined && this.formControl.value !== null;
  }

  onSelectionChange(event) {
    this.setValueToFormControlName(event.value, this.formItem.options);
    this.selectionChange.emit(event);
  }

  selectAll() {
    this.matSelectComponent.value = this.formItem.options.map(option => option.id);
    this.emitChange();
  }
  deselectAll() {
    this.matSelectComponent.value = null;
    this.emitChange();
  }

  emitChange() {
    // this.select.setValue(this.matSelectComponent.value);
    this.onSelectionChange({
      source: this.matSelectComponent,
      id: this.matSelectComponent.id,
    });
  }

  setValueToFormControlName(id: string | number, options: BitfOption[]) {
    if (this.setValueTo) {
      const option = this.searchValue(id, options);
      if (!option) {
        // Select groups
        this.formItem.options.some((item, _index, _arr) => this.searchValue(id, item.options));
      }
    }
  }

  searchValue(id: string | number, options: BitfOption[]): boolean {
    const option = options.find(item => item.id === id);
    if (option) {
      this.form.get(this.setValueTo).patchValue(option.label);
    }
    return option ? true : false;
  }
}
