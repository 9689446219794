import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { Company } from './company.model';

export class Group extends BitfStrapiModel {
  name: string;
  companies: Company[];

  constructor(data: Partial<Group> = {}) {
    super({ data });
    if (data.companies) {
      this.companies = data.companies.map(company => new Company(company));
    }
  }

  get serialised() {
    return {};
  }
}
