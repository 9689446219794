import { OnInit, Input, Output, EventEmitter, Component } from '@angular/core';

import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

import { ApiCallStateService } from '@services';
import { EApiCallStateNames, EApiRequestPartKeys } from '@enums';
import { IBitfApiPagination, IBitfPaginatorOptions } from '@interfaces';

@Component({
  selector: 'bitf-paginator',
  template: '',
  standalone: false,
})
export class BitfPaginatorComponent implements OnInit {
  @Input()
  apiCallStateName: EApiCallStateNames;

  @Input()
  options: IBitfPaginatorOptions;
  @Input()
  pageSizeOptions = [10, 30, 50, 100];
  // NOTE: mat paginator first page starts with 0 index
  @Input()
  pageIndexOffset = 0;

  @Input() autoScrollTop = true;
  @Input() autoScrollSelector = '';

  _pagination: IBitfApiPagination;
  @Input()
  set pagination(value: IBitfApiPagination) {
    this._pagination = value;
    this.updatePaginator();
  }
  get pagination(): IBitfApiPagination {
    return this._pagination;
  }

  @Output()
  pageChange: EventEmitter<IBitfApiPagination> = new EventEmitter();

  requestPart: BitfApiRequestPart;

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit() {
    if (this.apiCallStateName) {
      this.requestPart = this.apiCallStateService.getRequestPart(
        this.apiCallStateName,
        EApiRequestPartKeys.PAGINATION
      );

      if (this.requestPart?.options?.pageSizeOptions) {
        this.pageSizeOptions = this.requestPart.options.pageSizeOptions;
      }
      if (this.requestPart?.options?.pageIndexOffset) {
        this.pageIndexOffset = this.requestPart.options.pageIndexOffset;
      }
    }
  }

  onPageChange(pagination: IBitfApiPagination) {
    if (this.apiCallStateName) {
      this.apiCallStateService.setStore(() => {
        this.requestPart.data = {
          page: pagination.page,
          size: pagination.size,
        };
      }, this.apiCallStateName);
    }

    if (this.autoScrollTop) {
      const scrollOpt: ScrollToOptions = { top: 0, left: 0, behavior: 'smooth' };
      if (this.autoScrollSelector) {
        const element = document.querySelector(this.autoScrollSelector);
        if (element) {
          element.scrollTo(scrollOpt);
        }
      } else {
        window.scrollTo(scrollOpt);
      }
    }

    this.pageChange.emit(pagination);
  }

  updatePaginator() {}
}
