<section class="text-center">

  <div class="w-100 text-center mt-5"
    *ngIf="opportunities.length === 0 && !loaderService?.isLoading">
    <h4>{{'OPPORTUNITIES.EMPTY_LIST_OPPORTUNITIES' | translate}}</h4>
  </div>

  <table mat-table
    [dataSource]="opportunities"
    matSort
    class="mt-2"
    *ngIf="opportunities && opportunities.length >0">

    <ng-container matColumnDef="{{column}}"
      *ngFor="let column of displayedColumns"
      [ngSwitch]="column">

      <ng-container *ngSwitchCase="'opportunityId'">
        <th mat-header-cell
          *matHeaderCellDef>
          <ng-container [ngTemplateOutlet]="sortColumnComponent"
            [ngTemplateOutletContext]="{column}">
          </ng-container>
        </th>
        <td mat-cell
          *matCellDef="let element"> {{element.opportunityId}} </td>
        <td mat-footer-cell
          *matFooterCellDef></td>
      </ng-container>

      <ng-container *ngSwitchCase="'opportunityName'">
        <th mat-header-cell
          *matHeaderCellDef>
          <ng-container [ngTemplateOutlet]="sortColumnComponent"
            [ngTemplateOutletContext]="{column}">
          </ng-container>
        </th>
        <td mat-cell
          *matCellDef="let element"> {{element.opportunityName}} </td>
        <td mat-footer-cell
          *matFooterCellDef></td>
      </ng-container>

      <ng-container *ngSwitchCase="'businessName'">
        <th mat-header-cell
          *matHeaderCellDef>
          <ng-container [ngTemplateOutlet]="sortColumnComponent"
            [ngTemplateOutletContext]="{column}">
          </ng-container>
        </th>
        <td mat-cell
          *matCellDef="let element"> {{element.businessName}} </td>
        <td mat-footer-cell
          *matFooterCellDef></td>
      </ng-container>

      <ng-container *ngSwitchCase="'seller'">
        <th mat-header-cell
          *matHeaderCellDef>
          <ng-container [ngTemplateOutlet]="sortColumnComponent"
            [ngTemplateOutletContext]="{column}">
          </ng-container>
        </th>
        <td mat-cell
          *matCellDef="let element"> {{element.seller}} </td>
        <td mat-footer-cell
          *matFooterCellDef></td>
      </ng-container>

      <ng-container *ngSwitchCase="'opportunityUpdatedAt'">
        <th mat-header-cell
          *matHeaderCellDef>
          <ng-container [ngTemplateOutlet]="sortColumnComponent"
            [ngTemplateOutletContext]="{column}">
          </ng-container>
        </th>
        <td mat-cell
          *matCellDef="let element">
          {{element.opportunityUpdatedAt | bitfDate:'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container *ngSwitchCase="'state'">
        <th mat-header-cell
          *matHeaderCellDef>

          <ng-container [ngTemplateOutlet]="sortColumnComponent"
            [ngTemplateOutletContext]="{column}">
          </ng-container>
        </th>
        <td mat-cell
          *matCellDef="let element">

          <tim-status-chip [status]="element.state"
            [type]="eStatusType.OPPORTUNITY"
            [isEditable]="false"></tim-status-chip>
        </td>
        <td mat-footer-cell
          *matFooterCellDef></td>
      </ng-container>

      <ng-container *ngSwitchCase="'action'">
        <th mat-header-cell
          *matHeaderCellDef>
        </th>
        <td mat-cell
          [routerLink]="'/opportunities/'+[element.documentId]"
          class="cursor-pointer"
          *matCellDef="let element"> <mat-icon>visibility</mat-icon> </td>
        <td mat-footer-cell
          *matFooterCellDef></td>
      </ng-container>

    </ng-container>

    <tr mat-header-row
      *matHeaderRowDef="displayedColumns;sticky:true"></tr>
    <tr mat-row
      *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <bitf-mat-paginator *ngIf="opportunities && opportunities.length >0"
    [pagination]="pagination"
    [apiCallStateName]="apiCallStateName"
    [showFirstLastButtons]="true"></bitf-mat-paginator>
</section>


<ng-template #sortColumnComponent
  let-column="column">
  <bitf-mat-sort *ngIf="orderSortings[column]"
    [apiCallStateName]="apiCallStateName"
    [apiRequestPartKey]="orderSortings[column].key"
    [option]="orderSortings[column]"
    [class]="'btn-neutral'"></bitf-mat-sort>
</ng-template>