import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@common/libs/bitforce/core/services/api/bitf-api.service';
import { PurchaseRequest } from '@common/core/models/purchase-request.model';
import { IBitfApiRequest } from '@common/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PurchaseRequestsService extends BitfApiService {
  constructor(public injector: Injector) {
    super('purchase-requests', injector);
  }

  updatePurchaseRequest(apiRequest: IBitfApiRequest) {
    return this.put<PurchaseRequest>({
      ...apiRequest,
    });
  }
}
