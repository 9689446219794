import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { BitfApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-api-request-part';

import { ApiCallStateService } from '@common/core/services';
import { EApiCallStateNames, EApiRequestPartKeys } from '@web/enums';

@Component({
  selector: 'bitf-form-item-container',
  templateUrl: './bitf-form-item-container.component.html',
  styleUrls: ['./bitf-form-item-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BitfFormItemContainerComponent implements OnInit, OnDestroy {
  @Input() apiCallStateName: EApiCallStateNames;
  @Input() apiRequestPartKey: EApiRequestPartKeys;
  @Input() form: UntypedFormGroup;

  @Output() submitForm: EventEmitter<void> = new EventEmitter();

  private requestPart: BitfApiRequestPart;
  private subscription: Subscription = new Subscription();

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit(): void {
    this.requestPart = this.apiCallStateService.getRequestPart(this.apiCallStateName, this.apiRequestPartKey);

    this.patchForm();
    this.subscription = this.apiCallStateService.getStateStore$(this.apiCallStateName).subscribe(() => {
      this.patchForm();
    });
  }

  private patchForm() {
    const formValue = this.requestPart.formValue;
    if (formValue) {
      this.form.patchValue(formValue);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
