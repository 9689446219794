import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { Contract } from './contract.model';
import { IRole } from '@common/interfaces/role.interface';
import { Group } from './group.model';

export class User extends BitfStrapiModel {
  name: string;
  surname: string;
  email: string;
  contracts: Contract[];
  groups: Group[];
  role: IRole;

  constructor(data: Partial<User>) {
    super({ data });

    if (data.contracts) {
      this.contracts = data.contracts.map(contract => new Contract(contract));
    }

    if (data.groups) {
      this.groups = data.groups.map(group => new Group(group));
    }
  }

  get serialised() {
    return {
      id: this.id,
      documentId: this.documentId,
      name: this.name,
      surname: this.surname,
      email: this.email,
      contracts: this.contracts.map(contract => contract.serialised),
    };
  }

  get isAdmin() {
    return this.role?.type === 'super_admin';
  }
}
