import { HttpRequest, HttpResponse } from '@angular/common/http';
import { IBitfApiPagination, IBitfApiResponse } from '../../services/api/bitf-api.interface';
import { IBitfEnvelopeMapper, IBitfEnvelopeMapperData } from '../bitf-parsers.interface';
import { BitfRestEnvelopeMapper } from '../rest-parser';
import { IBitfApiStrapiPaginationResponse } from './bitf-strapi-api.interface';
import { EBitfUiMessageTarget, EBitfUiMessageType } from '@common/enums';

export class BitfStrapiEnvelopeMapper extends BitfRestEnvelopeMapper implements IBitfEnvelopeMapper {
  constructor() {
    super();
  }

  map({ req, event }: IBitfEnvelopeMapperData): HttpResponse<IBitfApiResponse<any>> {
    event = super.map({ req, event });
    const mappedBody: IBitfApiResponse<any> = event.body;
    const originalBody = event.body.originalBody;

    if (originalBody != null) {
      if (originalBody.data) {
        mappedBody.content = originalBody.data;
      } else {
        // NOTE: For upload plugin; The response is without 'data'
        mappedBody.content = originalBody;
      }

      // NOTE: Map pagination if present
      if (originalBody.meta?.pagination) {
        mappedBody.pagination = this.mapPagination(req, originalBody.meta.pagination);
      }

      // NOTE: Assign the metadata
      if (originalBody.meta) {
        Object.keys(originalBody.meta)
          .filter(key => key !== 'pagination')
          .forEach(key => (mappedBody.metadata[key] = originalBody.meta[key]));
      }
    }

    // Manage errors from strapi
    if (!mappedBody.metadata.uiMessages?.length && originalBody?.error) {
      mappedBody.metadata.uiMessages = [
        {
          title: originalBody.error.name,
          message: originalBody.error.message,
          type: EBitfUiMessageType.ERROR,
          target: EBitfUiMessageTarget.TOAST,
        },
      ];
    }

    return event.clone({
      body: mappedBody,
    });
  }

  mapPagination(request: HttpRequest<any>, pagination: IBitfApiStrapiPaginationResponse): IBitfApiPagination {
    if (!pagination) {
      return;
    }

    const size = pagination.pageSize;
    const page = pagination.page;
    const totalItems = pagination.total;
    const totalPages = pagination.pageCount;
    const first = page === 1;
    const last = page === totalPages;
    let itemsInPage: number;
    if (last) {
      if (totalItems % size > 0) {
        itemsInPage = totalItems % size;
      } else {
        itemsInPage = size;
      }
    } else {
      if (size * page <= totalItems) {
        itemsInPage = size;
      } else {
        itemsInPage = 0;
      }
    }
    return {
      first,
      last,
      page,
      size,
      itemsInPage,
      totalItems,
      totalPages,
    };
  }
}
