import { BitfStrapiModel } from '@common/libs/bitforce/core/models';

export class EntryGoods extends BitfStrapiModel {
  invoiceNumber: number;
  emValue: number;
  emDate: Date;

  constructor(data: Partial<EntryGoods> = {}) {
    super({ data });
  }

  get serialised() {
    return {
      invoiceNumber: this.invoiceNumber,
      emValue: this.emValue,
      emDate: this.emDate,
    };
  }
}
