import { Component, OnDestroy, OnInit } from '@angular/core';
import { Opportunity } from '@common/core/models/opportunity.model';
import { IBitfApiPagination, IBitfApiSorting, IBitfSortOption } from '@common/interfaces';
import { BitfApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-api-request-part';
import { EApiCallStateNames, EApiRequestPartKeys, eStoreActions } from '@web/enums';
import { Subscription } from 'rxjs';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { ApiCallStateService, LoaderService, OpportunitiesService, StoreService } from '@web/core/services';
import { tap } from 'rxjs/operators';
import { EStatusType } from '@web/enums/status-type.enum';

@Component({
    selector: 'tim-opportunities-list',
    templateUrl: './opportunities-list.component.html',
    styleUrl: './opportunities-list.component.scss',
    standalone: false
})
export class OpportunitiesListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  private subscriptionApi = new Subscription();
  private paginationRequestPart: BitfApiRequestPart;
  private requestPart: BitfApiRequestPart;
  pagination: IBitfApiPagination;
  eApiRequestPartKeys = EApiRequestPartKeys;
  apiCallStateName = EApiCallStateNames.OPPORTUNITIES;

  public eStatusType = EStatusType;
  opportunities: Opportunity[] = [];

  displayedColumns: string[] = [
    'opportunityId',
    'opportunityName',
    'businessName',
    'seller',
    'opportunityUpdatedAt',
    'state',
    'action',
  ];

  public orderSortings = {
    opportunityId: {
      key: EApiRequestPartKeys.OPPORTUNITY_ID_SORTING,
      label: bitfToTranslate('OPPORTUNITIES.OPPORTUNITY_ID'),
      value: { property: 'opportunityId' } as IBitfApiSorting,
    } as IBitfSortOption,
    opportunityName: {
      key: EApiRequestPartKeys.OPPORTUNITY_NAME_SORTING,
      label: bitfToTranslate('OPPORTUNITIES.OPPORTUNITY_NAME'),
      value: { property: 'opportunityName' } as IBitfApiSorting,
    } as IBitfSortOption,
    businessName: {
      key: EApiRequestPartKeys.BUSINESS_NAME_SORTING,
      label: bitfToTranslate('OPPORTUNITIES.BUSINESS_NAME'),
      value: { property: 'businessName' } as IBitfApiSorting,
    } as IBitfSortOption,
    seller: {
      key: EApiRequestPartKeys.SELLER_SORTING,
      label: bitfToTranslate('COMMON.LABEL.SELLER'),
      value: { property: 'seller' } as IBitfApiSorting,
    } as IBitfSortOption,
    opportunityUpdatedAt: {
      key: EApiRequestPartKeys.OPPORTUNITY_UPDATED_AT_SORTING,
      label: bitfToTranslate('OPPORTUNITIES.OPPORTUNITY_UPDATED_AT'),
      value: { property: 'opportunityUpdatedAt' } as IBitfApiSorting,
    } as IBitfSortOption,
    state: {
      key: EApiRequestPartKeys.STATE_SORTING,
      label: bitfToTranslate('COMMON.LABEL.STATE'),
      value: { property: 'state' } as IBitfApiSorting,
    } as IBitfSortOption,
  };

  constructor(
    public storeService: StoreService,
    public loaderService: LoaderService,
    private apiCallStateService: ApiCallStateService,
    private opportunitiesService: OpportunitiesService
  ) {}

  ngOnInit(): void {
    this.paginationRequestPart = this.apiCallStateService.getRequestPart(
      this.apiCallStateName,
      EApiRequestPartKeys.PAGINATION
    );

    this.subscription.add(
      this.storeService.selectStore(eStoreActions.SET_GROUP).subscribe(() => {
        this.loadData();
      })
    );

    this.subscription.add(
      this.apiCallStateService
        .getStateStore$(this.apiCallStateName)
        .pipe(tap(() => this.loadData()))
        .subscribe()
    );
  }

  private loadData() {
    this.loaderService.show();
    const apiRequest = this.apiCallStateService.getApiRequest(this.apiCallStateName);

    this.subscriptionApi.unsubscribe();
    this.subscriptionApi = new Subscription();
    this.subscriptionApi.add(
      this.opportunitiesService.getOpportunities(apiRequest).subscribe(response => {
        this.opportunities = response.content;
        this.pagination = response.pagination;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionApi.unsubscribe();
  }
}
