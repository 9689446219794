<mat-toolbar class="toolbar-lowest mt-4 sticky-top-page">
  <div class="page-header d-flex align-items-center w-100">
    <div class="col d-flex flex-grow-1 align-items-center">
      <button mat-icon-button
        *ngIf="backUrl"
        class="mr-2 back-button"
        [routerLink]="backUrl"><mat-icon>arrow_back</mat-icon></button>
      <h3 class="m-0 title">{{pageTitle | translate}}</h3>
    </div>

    <div class="col-auto d-flex align-items-center justify-content-end pl-0">
      <ng-content select="[content-right]"></ng-content>
    </div>
  </div>
</mat-toolbar>