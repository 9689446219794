import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { Opportunity } from './opportunity.model';
import { Company } from './company.model';

export class Contract extends BitfStrapiModel {
  name: string;
  contractId: string;
  aq: string;
  seller: string;
  conventionActivationDate: Date;
  conventionDurationInMonths: number;
  renewalDurationInMonths: number;
  opportunities: Opportunity[];
  company: Company;

  constructor(data: Partial<Contract> = {}) {
    super({ data });
    if (data.conventionActivationDate) {
      this.conventionActivationDate = new Date(data.conventionActivationDate);
    }
    if (data.opportunities) {
      this.opportunities = data.opportunities.map(opportunity => new Opportunity(opportunity));
    }

    if (data.company) {
      this.company = new Company(data.company);
    }
  }

  get serialised() {
    return {
      id: this.id,
      documentId: this.documentId,
      name: this.name,
      opportunities: this.opportunities.map(opportunity => opportunity.serialised),
      contractId: this.contractId,
      aq: this.aq,
      seller: this.seller,
      conventionActivationDate: this.conventionActivationDate,
      conventionDurationInMonths: this.conventionDurationInMonths,
      renewalDurationInMonths: this.renewalDurationInMonths,
    };
  }
}
