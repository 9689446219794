import {
  BitfStrapiEnvelopeMapper,
  BitfStrapiPathBuilder,
  BitfStrapiRequestMapper,
  BitfStrapiResponseMapper,
} from '@common/libs/bitforce/core/parsers/strapi-parser';

import { IBitfParserStrategy } from '@interfaces';

export const BITF_CONFIGS: any = {
  apiErrorsInterceptorService: { interceptHttpResponsesWithCode: [401, '4XX', '5XX'] },
  toastMessage: { durationMs: 5000 },
  parsers: {
    defaultParser: 'strapiParser',
    parserStrategies: {
      strapiParser: {
        pathBuilder: new BitfStrapiPathBuilder(),
        requestMapper: new BitfStrapiRequestMapper(),
        envelopeMapper: new BitfStrapiEnvelopeMapper(),
        responseMapper: new BitfStrapiResponseMapper(),
      } as IBitfParserStrategy,
    },
  },
};
