<mat-toolbar class="toolbar-primary">
  <button mat-icon-button
    class="d-none d-sm-block icon-button-white mr-3"
    (click)="isSidenavOpen ? closeSidenav() : onOpenSidenav()">
    <mat-icon>menu</mat-icon>
  </button>
  <img src="assets/logo/tim.svg">
  <div class="header-separator mx-4"></div>
  <span>{{'COMMON.LABEL.SALES_PLATFORM' | translate}}</span>
  <span class="d-flex flex-grow-1"></span>

  <div class="d-flex">
    <mat-form-field class="form-field-condensed"
      *ngIf="storeService.store.user.isAdmin && storeService.store.user.groups.length > 1">
      <mat-select [formControl]="groupFormControl"
        [compareWith]="groupCompare">
        <mat-option *ngFor="let group of storeService.store.user?.groups"
          [value]="group">{{group.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <button mat-button
    [matMenuTriggerFor]="menu"
    #t="matMenuTrigger">
    <div class="d-flex">
      <div class="d-flex m-auto">
        <mat-icon> account_circle</mat-icon>
      </div>
      <div class="mx-2">
        {{storeService.store.user.name}} {{storeService.store.user.surname}}
      </div>
      <div class="d-flex m-auto">
        <mat-icon>{{t.menuOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
      </div>
    </div>
  </button>
  <mat-menu #menu="matMenu"
    xPosition="before">
    <button mat-menu-item
      (click)="onSignOut()">{{'COMMON.LABEL.LOGOUT' | translate}}</button>
  </mat-menu>
</mat-toolbar>