import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { IBitfSidenavContent, IBitfCloseEvent } from '@interfaces';
import { Subject } from 'rxjs';
import { EBitfCloseEventStatus, EBitfSidenavPosition } from '@enums';
import { IBitfMatSidenav } from './bitf-mat-sidenav.interface';

@Injectable({
  providedIn: 'root',
})
export class BitfMatMultipleSidenavGlobalService {
  initialised = false;

  startSidenav: IBitfMatSidenav;
  endSidenav: IBitfMatSidenav;

  constructor(private resolver: ComponentFactoryResolver) {}

  init(startSidenav: IBitfMatSidenav, endSidenav: IBitfMatSidenav) {
    this.startSidenav = { ...startSidenav, subject: new Subject<IBitfCloseEvent<unknown>>() };
    this.endSidenav = { ...endSidenav, subject: new Subject<IBitfCloseEvent<unknown>>() };

    this.initialised = true;
  }

  open(data: IBitfSidenavContent) {
    if (!this.initialised) {
      throw Error('sidenav: component not initialised yet!');
    }

    const currentSidenav = this.getSidenav(data.sidenavOptions.position);
    currentSidenav.sidenavContent.clear();
    data.componentFactory = this.resolver.resolveComponentFactory(data.component);
    if (data.componentFactory) {
      currentSidenav.componentRef = currentSidenav.sidenavContent.createComponent(data.componentFactory);
      Object.assign(currentSidenav.componentRef.instance, data.componentData || {});
    } else {
      throw Error('sidenav: Component factory not initialised');
    }

    Object.assign(currentSidenav.sidenav, this.getDefaultOptions(), data.sidenavOptions);
    currentSidenav.sidenav.open();
    this.completeAllSubject(data.sidenavOptions.position);
    return currentSidenav.subject;
  }

  private getDefaultOptions() {
    return {
      mode: 'over',
      position: 'end',
      disableClose: true,
    };
  }

  async close<T>(
    sidenavEvent: IBitfCloseEvent<T> = { status: EBitfCloseEventStatus.CLOSE },
    position?: EBitfSidenavPosition
  ) {
    const currentSidenav = this.getSidenav(position);
    await currentSidenav.sidenav.close();
    currentSidenav.componentRef.destroy();
    currentSidenav.sidenavContent.clear();
    currentSidenav.subject.next(sidenavEvent);
  }

  completeAllSubject(position?: EBitfSidenavPosition) {
    this.getSidenav(position).subject.observers.forEach(observer => observer.complete());
  }

  getSidenav(position?: EBitfSidenavPosition) {
    return position === EBitfSidenavPosition.START ? this.startSidenav : this.endSidenav;
  }
}
