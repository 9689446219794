export enum EApiCallStateNames {
  CONTRACTS = 'CONTRACTS',
  OPPORTUNITIES = 'OPPORTUNITIES',
}

export enum EApiRequestPartKeys {
  SEARCH = 'SEARCH',
  PAGINATION = 'PAGINATION',
  FILTERS = 'FILTERS',
  SORTING = 'SORTING',
  AQ_SORTING = 'AQ_SORTING',
  SELLER_SORTING = 'SELLER_SORTING',
  CONVENTION_ACTIVATION_DATE_SORTING = 'CONVENTION_ACTIVATION_DATE_SORTING',
  CONVENTION_DURATION_IN_MONTHS_SORTING = 'CONVENTION_DURATION_IN_MONTHS_SORTING',
  RENEWAL_DURATION_IN_MOTHS_SORTING = 'RENEWAL_DURATION_IN_MOTHS_SORTING',
  CREATION_SORTING = 'CREATION_SORTING',
  OPPORTUNITY_ID_SORTING = 'OPPORTUNITY_ID_SORTING',
  OPPORTUNITY_NAME_SORTING = 'OPPORTUNITY_NAME_SORTING',
  BUSINESS_NAME_SORTING = 'BUSINESS_NAME_SORTING',
  OPPORTUNITY_UPDATED_AT_SORTING = 'OPPORTUNITY_UPDATED_AT_SORTING',
  STATE_SORTING = 'STATE_SORTING',
}
