<form *ngIf="form"
  (ngSubmit)="onOk()">
  <ng-container *ngTemplateOutlet="okCancelDialogTemplate;context:{dialogData, form}">
  </ng-container>
</form>

<ng-container *ngIf="!form">
  <ng-container *ngTemplateOutlet="okCancelDialogTemplate;context:{dialogData}">
  </ng-container>
</ng-container>


<ng-template #okCancelDialogTemplate
  let-dialogData="dialogData"
  let-form="form">
  <h2 mat-dialog-title>{{ dialogData.title | translate }}</h2>
  <mat-dialog-content>
    <span *ngIf="dialogData.message"
      class="message"
      [innerHTML]="dialogData.message | translate">
    </span>

    <div *ngIf="form"
      [formGroup]="form"
      class="mt-4">
      <div [ngSwitch]="dialogData.prompt?.inputType">

        <mat-checkbox *ngSwitchCase="'checkbox'"
          formControlName="value">
          <span>{{ dialogData.prompt?.label | translate }}</span>
        </mat-checkbox>

        <mat-form-field *ngSwitchDefault
          appearance="outline"
          class="w-100">
          <mat-label>{{ dialogData.prompt?.label | translate }}</mat-label>
          <input matInput
            [type]="dialogData.prompt?.inputType || 'text'"
            placeholder="{{ dialogData.prompt?.placeholder | translate }}"
            formControlName="value" />
        </mat-form-field>

      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mt-1 justify-content-end">
    <button mat-button
      (click)="onCancel()"
      type="button"
      *ngIf="dialogData.cancelText">
      {{ dialogData.cancelText | translate }}
    </button>

    <!-- Use without form -->
    <button *ngIf="dialogData.okText && !form"
      mat-flat-button
      (click)="onOk()"
      class="ml-3"
      [color]="okButtonType"
      [ngClass]="dialogData.okButtonClass">
      {{ dialogData.okText | translate }}
    </button>

    <!-- Use with form -->
    <button *ngIf="form && dialogData.okText"
      mat-flat-button
      [color]="okButtonType"
      [ngClass]="dialogData.okButtonClass"
      [disabled]="form && !form.valid"
      type="submit"
      class="ml-3">
      {{ dialogData.okText | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>