import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
export enum EOpportunityStatus {
  OPEN = 'OPEN',
  CONTRACTUALIZED = 'CONTRACTUALIZED',
  PRODUCTION_AND_SIGNATURE_OF_ATTACHMENTS = 'PRODUCTION_AND_SIGNATURE_OF_ATTACHMENTS',
  TAKEN_OVER_CO = 'TAKEN_OVER_CO',
  TAKEN_OVER_PS = 'TAKEN_OVER_PS',
  TAKEN_OVER_DELIVERY_ON_SITE = 'TAKEN_OVER_DELIVERY_ON_SITE',
  TAKEN_OVER_PURCHASING = 'TAKEN_OVER_PURCHASING',
  SUPPLY_TESTING_EM_PARTIAL_OR_TOTAL = 'SUPPLY_TESTING_EM_PARTIAL_OR_TOTAL',
  INVOICED = 'INVOICED',
}

const toTranslate = [
  bitfToTranslate('OPPORTUNITIES.STATUS.OPEN'),
  bitfToTranslate('OPPORTUNITIES.STATUS.CONTRACTUALIZED'),
  bitfToTranslate('OPPORTUNITIES.STATUS.PRODUCTION_AND_SIGNATURE_OF_ATTACHMENTS'),
  bitfToTranslate('OPPORTUNITIES.STATUS.TAKEN_OVER_CO'),
  bitfToTranslate('OPPORTUNITIES.STATUS.TAKEN_OVER_PS'),
  bitfToTranslate('OPPORTUNITIES.STATUS.TAKEN_OVER_DELIVERY_ON_SITE'),
  bitfToTranslate('OPPORTUNITIES.STATUS.TAKEN_OVER_PURCHASING'),
  bitfToTranslate('OPPORTUNITIES.STATUS.SUPPLY_TESTING_EM_PARTIAL_OR_TOTAL'),
  bitfToTranslate('OPPORTUNITIES.STATUS.INVOICED'),
];
