<ng-container *ngIf="isEditable">
  <mat-select [hideSingleSelectionIndicator]="true"
    [(ngModel)]="status"
    (ngModelChange)="onStatusChange($event)"
    placeholder="{{'OPPORTUNITIES.SELECT_STATUS' | translate}}">
    <mat-option [value]="null">{{'OPPORTUNITIES.SELECT_STATUS' | translate}}</mat-option>
    <mat-select-trigger>
      <mat-chip [class]="statusClass">
        <div class="d-flex align-items-center">
          {{'OPPORTUNITIES.STATUS.'+eStatus[status] | translate}}
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
      </mat-chip>
    </mat-select-trigger>
    <mat-option *ngFor="let status of statusOptions"
      [value]="status">
      {{'OPPORTUNITIES.STATUS.'+eStatus[status] | translate}}
    </mat-option>
  </mat-select>
</ng-container>

<ng-container *ngIf="!isEditable && eStatus[status]">
  <mat-chip [class]="statusClass">{{'OPPORTUNITIES.STATUS.'+eStatus[status]
    | translate}}</mat-chip>
</ng-container>

<ng-container *ngIf="!isEditable && !eStatus[status]">
  <mat-chip [class]="statusClass">{{'OPPORTUNITIES.NO_SELECTED_STATUS' | translate}}</mat-chip>
</ng-container>