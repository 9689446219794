import { OnInit, Output, Input, EventEmitter, OnDestroy, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounce, filter, distinctUntilChanged, tap } from 'rxjs/operators';
import { timer, Subscription } from 'rxjs';

import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { ApiCallStateService } from '@services';
import { EApiRequestPartKeys } from '@enums';

@Component({
  selector: 'bitf-search',
  template: '',
  standalone: false,
})
export class BitfSearchComponent implements OnInit, OnDestroy {
  @Input()
  apiCallStateName: string;

  @Input()
  apiRequestPartKey = EApiRequestPartKeys.SEARCH;

  @Input()
  formKey: string;

  @Input()
  formKeys: string[];

  @Input()
  hasSearchIcon = true;

  @Input()
  hasCancelButton = false;

  @Input()
  isInToolbar = false;

  @Input()
  formFieldClass: string;

  @Input()
  size = 'sm'; // "sm" | "xl"

  @Input()
  label = '';

  @Input()
  placeholder = '';

  @Input() minLength = 3;

  @Input()
  debounceTimeMs = 500;

  @Input()
  invertedColor?: boolean;

  @Output()
  valueChanges: EventEmitter<any> = new EventEmitter();

  requestPart: BitfApiRequestPart;
  paginationRequestPart: BitfApiRequestPart;
  formControl: UntypedFormControl = new UntypedFormControl('');
  subscription: Subscription = new Subscription();
  isFirstSearchDone = false;

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit() {
    if (!this.formKey && !this.formKeys) {
      // NOTE: this will work as default
      this.formKeys = ['keyword'];
    }

    if (this.formKey) {
      this.formKeys = [this.formKey];
    }

    if (this.apiCallStateName) {
      this.requestPart = this.apiCallStateService.getRequestPart(
        this.apiCallStateName,
        this.apiRequestPartKey
      );

      this.paginationRequestPart = this.apiCallStateService.getRequestPart(
        this.apiCallStateName,
        EApiRequestPartKeys.PAGINATION
      );

      this.subscription = this.apiCallStateService.getStateStore$(this.apiCallStateName).subscribe(() => {
        this.patchWithApiRequestPart();
      });
      this.patchWithApiRequestPart();
    }

    this.formControl.valueChanges
      .pipe(
        debounce(() => timer(this.debounceTimeMs)),
        distinctUntilChanged(),
        // NOTE: the second || is to run the query if the user cancel the text
        filter(value => value.length >= this.minLength || (this.isFirstSearchDone && value.length === 0))
      )
      .subscribe(value => {
        this.isFirstSearchDone = true;
        if (this.apiCallStateName) {
          this.requestPart.formValue = {};
          this.formKeys.forEach(key => {
            this.requestPart.formValue[key] = value;
          });
          this.paginationRequestPart.reset();
          this.apiCallStateService.setStore(() => {}, this.apiCallStateName);
        }
        this.valueChanges.emit(value);
      });
  }

  clearSearchField() {
    this.formControl.setValue('');
    if (this.apiCallStateName) {
      this.requestPart.reset();
      this.paginationRequestPart.reset();
      this.apiCallStateService.setStore(() => {}, this.apiCallStateName);
    }
    this.valueChanges.emit('');
  }

  private patchWithApiRequestPart() {
    const formValue = this.requestPart.formValue;
    if (this.hasInitialValue()) {
      // NOTE: we use the first key because the content of all keys is the same
      this.formControl.patchValue(formValue[this.formKeys[0]], { emitEvent: false });
      this.isFirstSearchDone = true;
    } else {
      this.formControl.patchValue('', { emitEvent: false });
      this.isFirstSearchDone = false;
    }
  }

  protected hasInitialValue() {
    // NOTE: we use the first key because the content of all keys is the same
    const formValue = this.requestPart?.formValue;
    return formValue && formValue[this.formKeys[0]];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
