<mat-form-field *ngIf="isDropDown()"
  color="primary"
  [ngStyle]="{'font-size': fontSize}"
  [ngClass]="{'form-density-3 form-field-condensed': isInToolbar}"
  appearance="outline">
  <mat-label>{{ 'COMMON.LABEL.ORDER_BY' | translate}}</mat-label>
  <mat-select placeholder="{{ 'COMMON.LABEL.ORDER_BY' | translate}}"
    (selectionChange)="onSelectionChange($event)"
    [formControl]="formControl"
    subscriptSizing="dynamic">
    <mat-option [value]="option.value"
      *ngFor="let option of options">
      {{option.label | translate}}
    </mat-option>
  </mat-select>
</mat-form-field>

<ng-container *ngIf="!isDropDown()">
  <button mat-button
    [class]="class"
    [color]="color"
    (click)="sort(option)">
    {{option.label | translate}}
    <mat-icon *ngIf="option.value.direction === EBitfApiSortDirection.ASC">arrow_upward</mat-icon>
    <mat-icon *ngIf="option.value.direction === EBitfApiSortDirection.DESC">arrow_downward</mat-icon>
  </button>
</ng-container>