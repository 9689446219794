import { Component, OnInit } from '@angular/core';
import { APP_VERSION } from '@env/version';

@Component({
    selector: 'tim-menu',
    templateUrl: './menu.component.html',
    styleUrl: './menu.component.scss',
    standalone: false
})
export class MenuComponent implements OnInit {
  appVersion = APP_VERSION;

  constructor() {}

  ngOnInit(): void {}
}
