import { Component, Input } from '@angular/core';

import { BitfSortComponent } from '../bitf-sort.component';

@Component({
  selector: 'bitf-mat-sort',
  templateUrl: './bitf-mat-sort.component.html',
  styleUrls: ['./bitf-mat-sort.component.scss'],
  standalone: false,
})
export class BitfMatSortComponent extends BitfSortComponent {
  @Input() color: string;
  @Input() fontSize: string;
  @Input() isInToolbar = false;
  @Input() class: string;
}
