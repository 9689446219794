import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { Contract } from './contract.model';
import { IAttachment } from '@common/interfaces/attachments.interface';
import { eAttachmentFileName } from '@web/enums/attachnents-file-name.enum';
import { EOpportunityStatus } from '@web/enums/opportunity-status.enum';
import { Invoice } from './invoice.model';
import { WorkOrder } from './work-order.model';
import { Revenue } from './revenue.model';

export class Opportunity extends BitfStrapiModel {
  businessName: string;
  cig: string;
  contractActivationDate: string;
  contractAmount: number;
  contractDurationInMonths: number;
  contractId: Contract;
  dec: string;
  documentId: string;
  attachments: IAttachment[];
  invoices: Invoice[];
  opportunityId: string;
  opportunityName: string;
  orderId: string;
  orderCodeClient: string;
  publishedAt: Date;
  opportunityUpdatedAt: Date;
  res: string;
  tags: string;
  revenues: Revenue[];
  seller: string;
  state: EOpportunityStatus;
  workOrders: WorkOrder[];

  constructor(data: Partial<Opportunity>) {
    super({ data });

    if (data.publishedAt) {
      this.publishedAt = new Date(data.publishedAt);
    }

    if (data.opportunityUpdatedAt) {
      this.opportunityUpdatedAt = new Date(data.opportunityUpdatedAt);
    }

    if (data.invoices) {
      this.invoices = data.invoices.map(invoice => new Invoice(invoice));
    }

    if (data.workOrders) {
      this.workOrders = data.workOrders.map(workOrder => new WorkOrder(workOrder));
    }

    if (data.revenues) {
      this.revenues = data.revenues.map(revenue => new Revenue(revenue));
    }

    if (data.workOrders) {
      data.workOrders = data.workOrders.map(workOrder => {
        workOrder.totalEm = this.getTotalEm(workOrder);
        workOrder.purchaseRequests = workOrder?.purchaseRequests?.map(purchaseRequest => {
          purchaseRequest.idSiebel = workOrder.idSiebel;
          return purchaseRequest;
        });
        return workOrder;
      });
    }

    if (data.attachments) {
      eAttachmentFileName.forEach(name => {
        const existingAttachment = this.attachments.find(
          attachment => attachment.name.toLowerCase() === name
        );

        if (!existingAttachment) {
          this.attachments.push({
            id: null,
            name: name.charAt(0).toUpperCase() + name.slice(1),
            attachmentStatus: null,
          });
        }
      });
    }
  }

  get serialised() {
    return {
      state: this.state,
      attachments: this.attachments.map(attachment => {
        delete attachment?.id;
        return attachment;
      }),
      cig: this.cig,
      orderCodeClient: this.orderCodeClient,
      tags: this.tags,
      invoices: this.invoices?.map(invoice => invoice.documentId),
      workOrders: this.workOrders?.map(workOrder => workOrder.documentId),
    };
  }

  get tagsArray() {
    return this.tags ? this.tags.split(',') : [];
  }

  getTotalEm(workOrder): number {
    if (workOrder?.purchaseRequests) {
      return workOrder.purchaseRequests.reduce((total, purchaseRequest) => {
        const entryGoodsTotal = purchaseRequest.entryGoods?.reduce(
          (acc, entryGood) => acc + entryGood.emValue,
          0
        );
        return total + entryGoodsTotal;
      }, 0);
    } else {
      return 0;
    }
  }
}
