import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@common/libs/bitforce/core/services/api/bitf-api.service';
import { Invoice } from '@common/core/models/invoice.model';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService extends BitfApiService {
  constructor(public injector: Injector) {
    super('invoices', injector);
  }

  create(invoice: Invoice) {
    return this.post<Invoice>({
      body: invoice.serialised,
    });
  }

  deleteInvoice(invoice: Invoice) {
    return this.delete({
      id: invoice.documentId,
    });
  }
}
