import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { WorkOrder } from './work-order.model';
import { Opportunity } from './opportunity.model';

export class Invoice extends BitfStrapiModel {
  number: number;
  dateCreation: Date;
  total: number;
  opportunity: Opportunity;
  workOrder: WorkOrder;

  constructor(data: Partial<Invoice> = {}) {
    super({ data });

    if (data.opportunity) {
      this.opportunity = new Opportunity(data.opportunity);
    }

    if (data.workOrder) {
      this.workOrder = new WorkOrder(data.workOrder);
    }
  }

  get serialised() {
    return {
      number: this.number,
      dateCreation: this.dateCreation,
      total: this.total,
      opportunity: this.opportunity?.documentId,
      workOrder: this.workOrder?.documentId,
    };
  }
}
