import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@common/shared/common-shared.module';
import { MATERIAL_MODULES, CDK_MODULES } from './material-modules';

import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';
import { BitfMatPaginatorComponent } from '@bitf/core/components/ui/paginator/material/bitf-mat-paginator.component';
import { BitfUiRoleManagerDirective } from '@common/libs/bitforce/directives/ui-role-manager/bitf-ui-role-manager.directive';
import { BitfTouchEventDirective } from '@common/libs/bitforce/directives/bitf-touch-events/bitf-touch-event.directive';
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { BitfMatLoaderComponent } from '@bitf/core/components/ui/loader/material/bitf-mat-loader/bitf-mat-loader.component';
import { BitfImageComponent } from '@common/libs/bitforce/core/components/ui/image/bitf-image/bitf-image.component';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { DateAdapterService } from '@common/core/services';
import { HeaderComponent } from '@web/core/components/header/header.component';
import { MenuComponent } from '@web/core/components/menu/menu.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { BitfMatSortComponent } from '@common/libs/bitforce/core/components/ui/sort/material/bitf-mat-sort.component';
import { BitfMatSearchComponent } from '@common/libs/bitforce/core/components/ui/search/material/bitf-mat-search.component';
import { BitfFormItemContainerComponent } from '@common/libs/bitforce/core/components/ui/form-item/bitf-form-item-container/bitf-form-item-container.component';
import { BitfMatFormItemComponent } from '@common/libs/bitforce/core/components/ui/form-item/material/mat-form-item/bitf-mat-form-item.component';
import { OpportunitiesListComponent } from './components/opportunities-list/opportunities-list.component';
import { StatusChipComponent } from './components/status-chip/status-chip.component';
import { BitfMatFormOptionComponent } from '@common/libs/bitforce/core/components/ui/form-item/material/mat-form-option/bitf-mat-form-option.component';

const SHARED_MODULES = [CommonSharedModule, RouterModule, MATERIAL_MODULES, CDK_MODULES];
const SHARED_COMPONENTS = [
  BitfLoaderComponent,
  BitfMatPaginatorComponent,
  BitfUiRoleManagerDirective,
  BitfTouchEventDirective,
  BitfMatOkCancelDialogComponent,
  BitfMatLoaderComponent,
  BitfImageComponent,
  HeaderComponent,
  MenuComponent,
  PageHeaderComponent,
  BitfMatSortComponent,
  BitfMatSearchComponent,
  BitfFormItemContainerComponent,
  BitfMatFormItemComponent,
  OpportunitiesListComponent,
  StatusChipComponent,
  BitfMatFormOptionComponent,
];
const SHARED_DIRECTIVES = [];
const PROVIDERS = [DatePipe, { provide: DateAdapter, useClass: DateAdapterService }];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...PROVIDERS],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
