import { inject, Injectable, Injector } from '@angular/core';
import { Opportunity } from '@common/core/models';
import { IBitfApiRequest } from '@common/interfaces';
import { BitfApiService } from '@common/libs/bitforce/core/services/api/bitf-api.service';
import { StoreService } from '../store.service';

const OPPORTUNITY_DETAILS_POPULATE = {
  attachments: {
    populate: '*',
  },
  revenues: {
    populate: '*',
  },
  invoices: {
    populate: '*',
  },
  workOrders: {
    populate: {
      purchaseRequests: {
        populate: {
          entryGoods: true,
        },
      },
    },
  },
};

@Injectable({
  providedIn: 'root',
})
export class OpportunitiesService extends BitfApiService {
  /**
   * Imports
   */
  storeService = inject(StoreService);

  constructor(public injector: Injector) {
    super('opportunities', injector);
  }

  getOpportunities(apiRequest: IBitfApiRequest) {
    return this.get<Opportunity>({
      populate: '*',
      filter: {
        company: {
          documentId: { $in: this.storeService.store?.group?.companies?.map(c => c.documentId) },
        },
        ...apiRequest.filter,
      },
      ...apiRequest,
    });
  }

  getOpportunityDetails(id: string) {
    return this.getById<Opportunity>({
      id: id,
      populate: OPPORTUNITY_DETAILS_POPULATE,
    });
  }

  updateOpportunity(opportunity: Opportunity) {
    return this.put({
      id: opportunity.documentId,
      body: opportunity.serialised,
      populate: OPPORTUNITY_DETAILS_POPULATE,
    });
  }
}
